
<div class="home-navbar">
  <v-container class="navbar-container">
    <div class="navbar-content">
      <router-link to="/" class="logo-section">
        <div class="logo-image-container">
          <img
            class="logo-image"
            src="/assets/public/ucc_logo.png"
            alt="UCC Logo"
          />
        </div>
        <div class="logo-text">
          Get <span class="highlight-blue">Entry </span> to
          <span class="highlight-blue">Professional Journey</span>
        </div>
      </router-link>

      <div class="mobile-menu hidden-lg-and-up">
        <v-menu
          offset-y
          offset-x
          origin="top right"
          transition="scale-transition"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon large v-bind="attrs" v-on="on" class="menu-button">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <div v-for="(item, index) in menuItems" :key="index">
              <v-divider v-if="item.divider" class="my-2" />
              <v-list-item
                :to="item.href"
                exact-active-class="route-active"
                v-else
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="navigation-links">
      <v-btn text to="/home">Home</v-btn> <span>|</span>
      <v-btn text to="/about">About Us</v-btn> <span>|</span>
      <v-btn text to="/">Training</v-btn> <span>|</span>
      <v-btn text to="/care-providers">Care Provider</v-btn> <span>|</span>
      <v-btn text to="/care-workers-and-nurses">Care Workers & Nurses</v-btn>
      <span>|</span> <v-btn text to="/faq">FAQ</v-btn> <span>|</span>
      <v-btn text to="/ucc-login">Login</v-btn>
    </div>

    <div class="desktop-menu hidden-md-and-down">
      <div class="social-media-icons">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/urbancarecommunity"
              target="_blank"
            >
              <v-icon style="font-size: 32px; color: #005eb8">
                mdi-facebook
              </v-icon>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/ucarecommunity1" target="_blank">
              <v-icon style="font-size: 32px; color: #1da1f2">
                mdi-twitter
              </v-icon>
            </a>
          </li>
          <li>
            <a
              href="https://uk.linkedin.com/in/urbancare-community-31561b142"
              target="_blank"
            >
              <v-icon style="font-size: 32px; color: #0077b5">
                mdi-linkedin
              </v-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </v-container>
</div>
