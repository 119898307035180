import {
  CarerApplicationGuard,
  CarerGuard,
  CarerPortalGuard,
  CarerSubmittedGuard,
  CarerDisabledGuard,
} from "@auth/authGuards/carerGuard";
import {
  ClientGuard,
  ServiceUserGuard,
  ClientPortalGuard,
  ServiceUserPortalGuard,
  ClientSubmittedGuard,
} from "@auth/authGuards/clientGuard";
import { PublicAuthGuard } from "@auth/authGuards/publicGuard";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

// Public

const Home = () => import("../views/public/Home.vue");
const About = () => import("../views/public/About.vue");
const Training = () => import("../views/public/Training.vue");
const CareProviders = () => import("../views/public/CareProviders.vue");
const CareWorkers = () => import("../views/public/CareWorkers.vue");
const OpenJobPosts = () => import("../views/public/OpenJobPosts.vue");
const Faq = () => import("../views/public/Faq.vue");
const Covid19 = () => import("../views/public/Covid19.vue");
const ContactUs = () => import("../views/public/ContactUs.vue");
const DeleteAccount = () => import("../views/public/DeleteAccount.vue");
const CareProviderSignup = () =>
  import("../views/public/CareProviderSignup.vue");
const CareWorkerSignup = () => import("../views/public/CareWorkerSignup.vue");
const QMOSignup = () => import("../views/public/QMOSignup.vue");
const TechnologyEnabledCare = () =>
  import("../views/public/TechnologyEnabledCare.vue");
const LDCareGuide = () => import("../views/public/LDCareGuide.vue");
const LDCareCaq = () => import("../views/public/LDCareCaq.vue");
const LiveInCareGuide = () => import("../views/public/LiveInCareGuide.vue");
const LiveInCareCaq = () => import("../views/public/LiveInCareCaq.vue");
const FundingCareGuide = () => import("../views/public/FundingCareGuide.vue");
const DementiaCareGuide = () => import("../views/public/DementiaCareGuide.vue");
const DementiaCareCaq = () => import("../views/public/DementiaCareCaq.vue");
const NursingCareGuide = () => import("../views/public/NursingCareGuide.vue");
const Policies = () => import("../views/public/Policies.vue");
const SafeguardingAndPrevent = () =>
  import("../views/public/SafeguardingAndPrevent.vue");
const SaferRecruitmentAndSelection = () =>
  import("../views/public/SaferRecruitmentAndSelection.vue");
const HealthAndSafety = () => import("../views/public/HealthAndSafety.vue");
const EqualityDiversityAndInclusion = () =>
  import("../views/public/EqualityDiversityAndInclusion.vue");
const ComplaintsAndWhistleblowing = () =>
  import("../views/public/ComplaintsAndWhistleblowing.vue");
const DataProtectionAndConfidentiality = () =>
  import("../views/public/DataProtectionAndConfidentiality.vue");
const TermsAndConditions = () =>
  import("../views/public/TermsAndConditions.vue");
const PrivacyPolicy = () => import("../views/public/PrivacyPolicy.vue");
const SignUp = () => import("../views/public/SignUp.vue");
const Login = () => import("../views/public/Login.vue");
// Shared

const signUp = () => import("../views/shared/SignUp.vue");
const passReset = () => import("../views/shared/Reset.vue");
// const about = () => import('../views/shared/about.vue')
const clients = () => import("../views/shared/Clients.vue");
const careGiver = () => import("../views/shared/CareGivers.vue");
const documents = () => import("../views/shared/Documents.vue");
const TOS = () => import("../views/shared/Terms.vue");
const privacyPolicy = () => import("../views/shared/PrivacyPolicy.vue");
const signIn = () => import("../views/shared/SignIn.vue");
const notFound = () => import("@components/shared/NotFound.vue");
const cookieDeclaration = () => import("../views/CookieDeclaration.vue");

const clientSignUp = () => import("../views/client/SignUp.vue");
const clientHome = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/Index.vue"
  );
const clientPortal = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Index.vue"
  );
const clientSearch = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Search.vue"
  );
const clientProfile = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Profile.vue"
  );
const clientBook = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Book/Index.vue"
  );
const clientBookPreview = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Book/Preview/Index.vue"
  );
const clientCarer = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/CarerProfile.vue"
  );
const clientBooking = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/booking/Index.vue"
  );
const clientEditBooking = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Book/Edit.vue"
  );
const clientShift = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/Shift/Index.vue"
  );
const clientSettings = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/client/portal/Settings.vue"
  );
const clientJobPost = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/client/portal/JobPost/index.vue"
  );
const clientAddJobPost = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/client/portal/JobPost/addJobPost.vue"
  );
const clientPreviewJobPost = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/JobPost/PreviewJobPost/Index.vue"
  );
const clientDetailJobPost = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/JobPost/JobPostDetail.vue"
  );

const clientJobPostShiftDetails = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/JobPost/ShiftDetails.vue"
  );
const clientPaymentMethods = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/client/portal/settings/PaymentMethods.vue"
  );
const clientBookings = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/bookings/Index.vue"
  );

const clientServiceUsers = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/ServiceUsers/Index.vue"
  );

const clientServiceUser = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/ServiceUser/ServiceUser.vue"
  );

const clientServiceUserForm = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/ServiceUser/TaskForm.vue"
  );

const clientSubmitted = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/Submitted.vue"
  );
const serviceUserHome = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/ServiceUserPortal.vue"
  );
const serviceUserPortal = () =>
  import(
    /* webpackChunkName: "client" */
    "../views/client/portal/ServiceUserPortal.vue"
  );

const carerSignUp = () => import("../views/carer/SignUp.vue");
const carerPortal = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Index.vue"
  );
const carerProfile = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Profile.vue"
  );
const carerAvailability = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Availability/Availability.vue"
  );
const carerBookings = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Bookings/Index.vue"
  );
const carerJob = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Job/Index.vue"
  );
const carerShift = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Shift/Index.vue"
  );
const carerFindJobs = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/FindJobs.vue"
  );
const carerJobPosts = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/JobPost/Index.vue"
  );
const carerJobPostDetail = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/JobPost/DetailView.vue"
  );
const carerJobPostShiftDetail = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/JobPost/ShiftDetails.vue"
  );
const carerSettings = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Settings.vue"
  );
const carerJobRoles = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Settings/JobRoles/Index.vue"
  );
const carerRateOfPay = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Settings/RateOfPay.vue"
  );

const carerStripe = () =>
  import(
    /* webpackChunkName: "carer" */
    "../views/carer/portal/Stripe.vue"
  );

const carerApplication = () =>
  import(
    /* webpackChunkName: "carerApp" */
    "../views/carer/application/Index.vue"
  );
const carerAppPreview = () =>
  import(
    /* webpackChunkName: "carerApp" */
    "../views/carer/application/PreviewApplication.vue"
  );
const carerAppSubmitted = () =>
  import(
    /* webpackChunkName: "carerApp" */
    "../views/carer/application/AppDone.vue"
  );
const carerDisabled = () =>
  import(
    /* webpackChunkName: "carerApp" */
    "../views/carer/Disabled.vue"
  );
const SetPin = () => import("../views/client/portal/settings/Pin.vue");
const ResetPin = () => import("../views/client/portal/settings/ResetPin.vue");

const carerHome = () =>
  import(/* webpackChunkName: "carer" */ "../views/carer/Index.vue");

const signOut = () => import("../views/carer/Signout.vue");

const routes = [
  { path: "/", component: Training, beforeEnter: PublicAuthGuard },
  {
    path: "/home",
    component: Home,
    beforeEnter: PublicAuthGuard,
  },
  { path: "/documents", component: documents, beforeEnter: PublicAuthGuard },
  { path: "/tos", component: TOS, beforeEnter: PublicAuthGuard },
  {
    path: "/privacy-policy",
    component: privacyPolicy,
    beforeEnter: PublicAuthGuard,
  },
  { path: "/about", component: About, beforeEnter: PublicAuthGuard },
  // { path: "/training", component: Training, beforeEnter: PublicAuthGuard },
  {
    path: "/care-providers",
    component: CareProviders,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/care-workers-and-nurses",
    component: CareWorkers,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/job-posts",
    component: OpenJobPosts,
    beforeEnter: PublicAuthGuard,
  },
  { path: "/faq", component: Faq, beforeEnter: PublicAuthGuard },
  { path: "/covid19", component: Covid19, beforeEnter: PublicAuthGuard },
  { path: "/contact-us", component: ContactUs, beforeEnter: PublicAuthGuard },
  {
    path: "/delete-account",
    component: DeleteAccount,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/care-provider-signup",
    component: CareProviderSignup,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/care-worker-and-nurses-signup",
    component: CareWorkerSignup,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/qmo-signup",
    component: QMOSignup,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/technology-enabled-care",
    component: TechnologyEnabledCare,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/safeguarding-and-prevent",
    component: SafeguardingAndPrevent,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/safer-recruitment-and-selection",
    component: SaferRecruitmentAndSelection,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/health-and-safety",
    component: HealthAndSafety,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/equality-diversity-and-inclusion",
    component: EqualityDiversityAndInclusion,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/complaints-and-whistleblowing",
    component: ComplaintsAndWhistleblowing,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/data-protection-and-confidentiality-statement",
    component: DataProtectionAndConfidentiality,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/learning-disability-care-guide",
    component: LDCareGuide,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/learning-disability-care-caq",
    component: LDCareCaq,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/live-in-care-guide",
    component: LiveInCareGuide,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/live-in-care-caq",
    component: LiveInCareCaq,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/funding-care-guide",
    component: FundingCareGuide,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/dementia-care-guide",
    component: DementiaCareGuide,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/dementia-care-caq",
    component: DementiaCareCaq,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/nursing-care-guide",
    component: NursingCareGuide,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/policies",
    component: Policies,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/ucc-privacy-policy",
    component: PrivacyPolicy,
    beforeEnter: PublicAuthGuard,
  },
  { path: "/sign-up", component: SignUp, beforeEnter: PublicAuthGuard },
  { path: "/ucc-login", component: Login, beforeEnter: PublicAuthGuard },
  { path: "/clients", component: clients, beforeEnter: PublicAuthGuard },
  { path: "/caregiver", component: careGiver, beforeEnter: PublicAuthGuard },
  {
    path: "/cookies",
    component: cookieDeclaration,
    beforeEnter: PublicAuthGuard,
  },
  {
    path: "/reset",
    component: passReset,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        return next("/");
      }
      return next();
    },
  },
  { path: "/sign-out", component: signOut },
  {
    path: "/sign-in",
    component: signIn,
    beforeEnter: (to, from, next) => {
      if (store.state.user && store.state.user.userType === "CLIENT") {
        return next("/client");
      }
      if (store.state.user && store.state.user.userType === "CARER") {
        return next("/carer");
      }
      return next();
    },
  },
  {
    path: "/signup",
    component: signUp,
    redirect: "/signup/client",
    beforeEnter: (to, from, next) => {
      if (store.state.user && store.state.user.userType === "CLIENT") {
        return next("/client");
      }
      if (store.state.user && store.state.user.userType === "CARER") {
        return next("/carer");
      }
      return next();
    },
    children: [
      {
        path: "client",
        component: clientSignUp,
      },
      {
        path: "carer",
        component: carerSignUp,
      },
    ],
  },
  {
    path: "/client",
    component: clientHome,
    redirect: "/client/portal",
    beforeEnter: ClientGuard,
    children: [
      {
        path: "portal",
        component: clientPortal,
        redirect: "/client/portal/profile",
        beforeEnter: ClientPortalGuard,
        children: [
          {
            path: "search",
            component: clientSearch,
            name: "Care Workers",
          },
          {
            path: "profile",
            component: clientProfile,
            name: "Profile",
          },
          {
            path: "book",
            component: clientBook,
          },
          {
            path: "book/edit",
            component: clientEditBooking,
          },
          {
            path: "book/preview",
            component: clientBookPreview,
          },
          {
            path: "job-post",
            component: clientJobPost,
            name: "Post Job",
          },
          {
            path: "add-job-post",
            component: clientAddJobPost,
            name: "Post Job",
          },
          {
            path: "job-post/preview",
            component: clientPreviewJobPost,
          },
          {
            path: "job-post/:id",
            component: clientDetailJobPost,
          },
          {
            path: "job-post/:jobPostId/shift/:id",
            component: clientJobPostShiftDetails,
          },
          {
            path: "settings",
            component: clientSettings,
            name: "Settings",
          },
          {
            path: "settings/payment-methods",
            component: clientPaymentMethods,
            name: "Payment Methods",
          },
          {
            path: "settings/authentication-pin",
            component: SetPin,
            name: "Authentication Pin",
          },
          {
            path: "reset/authentication-pin/:token",
            component: ResetPin,
          },
          {
            path: "carer/:id",
            component: clientCarer,
            name: "Carer Worker Details",
          },
          {
            path: "booking/:id",
            component: clientBooking,
            name: "Booking",
          },
          {
            path: "shift/:id",
            component: clientShift,
          },
          {
            path: "bookings",
            component: clientBookings,
            name: "Bookings",
          },
          {
            path: "users/:id",
            component: clientServiceUser,
            name: "Service Users",
          },
          {
            path: "users/:id/tasks/:taskId?",
            component: clientServiceUserForm,
            name: "Create Task List",
          },
          {
            path: "users",
            component: clientServiceUsers,
            name: "Service Users",
          },
        ],
      },
      {
        path: "submitted",
        component: clientSubmitted,
        beforeEnter: ClientSubmittedGuard,
      },
    ],
  },
  {
    path: "/service-user",
    component: serviceUserHome,
    redirect: "/service-user/portal",
    beforeEnter: ServiceUserGuard,
    children: [
      {
        path: "portal",
        component: serviceUserPortal,
        redirect: "/service-user/portal/bookings",
        beforeEnter: ServiceUserPortalGuard,
        children: [
          {
            path: "booking/:id",
            component: clientBooking,
            name: "Booking",
          },
          {
            path: "bookings",
            component: clientBookings,
            name: "Booking",
          },
        ],
      },
    ],
  },
  {
    path: "/bookings",
    component: clientBookings,
    name: "Booking",
  },
  {
    path: "/carer",
    redirect: "/carer/portal",
    component: carerHome,
    beforeEnter: CarerGuard,
    children: [
      {
        path: "portal",
        component: carerPortal,
        redirect: "/carer/portal/profile",
        beforeEnter: CarerPortalGuard,
        children: [
          {
            path: "profile",
            component: carerProfile,
            name: "Profile",
          },
          {
            path: "availability/:day",
            component: carerAvailability,
            name: "Availabilities",
          },
          {
            path: "availability",
            component: carerAvailability,
            name: "Availabilities",
          },
          {
            path: "settings",
            component: carerSettings,
            name: "Settings",
          },
          {
            path: "settings/stripe",
            component: carerStripe,
            name: "Add Bank Details",
          },
          {
            path: "settings/job-types",
            component: carerJobRoles,
            name: "Pay Rate",
          },
          {
            path: "settings/rate-of-pay",
            component: carerRateOfPay,
          },
          {
            path: "find_jobs",
            component: carerFindJobs,
          },
          {
            path: "bookings",
            component: carerBookings,
            name: "Bookings",
          },
          {
            path: "booking/:id",
            component: carerJob,
            name: "Booking",
          },
          {
            path: "shift/:id",
            component: carerShift,
          },
          {
            path: "job-post",
            component: carerJobPosts,
            name: "Posted Job",
          },
          {
            path: "job-post/:id",
            component: carerJobPostDetail,
          },
          {
            path: "job-post/shift/:id",
            component: carerJobPostShiftDetail,
          },
        ],
      },
      {
        path: "application/submitted",
        component: carerAppSubmitted,
        beforeEnter: CarerSubmittedGuard,
      },
      {
        path: "disabled",
        component: carerDisabled,
        beforeEnter: CarerDisabledGuard,
      },
      {
        path: "application/preview",
        component: carerAppPreview,
        beforeEnter: CarerApplicationGuard,
      },
      {
        path: "application",
        component: carerApplication,
        beforeEnter: CarerApplicationGuard,
      },
    ],
  },
  {
    path: "/carer/signout",
    beforeEnter: (to, from, next) => {
      store.commit("caSignOut");
      return next("/");
    },
  },
  {
    path: "/client/signout",
    beforeEnter: (to, from, next) => {
      store.commit("clClear");
      return next("/");
    },
  },
  {
    path: "/carer/*",
    beforeEnter: (to, from, next) => {
      if (!store.state.carer.token) {
        return next("/");
      }
      return next("/carer/portal");
    },
  },
  // ...adminRoutes,
  {
    path: "*",
    redirect: "/",
    component: notFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
